import { useEffect, useState } from "react";
//import { LoadScripts } from "./LoadScripts"; // Adjust the path as needed
import { LoadScripts } from "dsshps1";
import { Container, Button } from "react-bootstrap";
const Client = () => {
  const [formData] = useState({
    Merchant_User_Id: "test_GP_3d",
    PublicKey: "J4wXAqrk4einB2",
    TransKey:
      "42eadc6d53393af224999fb67ea907b36ca6edf2e1a9d43bd7dc91ca577a640d",
    AuthStatusCheck:
      "acbda47903d16b4a9e09d0089bef2091635a3735bdc3b50a7e6362b100c10481",
    DataKey: "fYEBywdbLfTu128XL7",
    Amount: "0.01",
    Surcharge: "0.00",
    amount: "0.01",
    Currency: "AUD",
    Description: "lpstest123",
    Reference: "lpstest123",
    Email: "hps@lpsmail.com",
    Firstname: "NA",
    Lastname: "NA",
  });

  const [result, setResult] = useState("");
  const [authStatusCheck, setAuthStatusCheck] = useState("");

  useEffect(() => {
    LoadScripts()
      .then(() => {
        const latpayElement = document.getElementById("latpay-element");
        if (latpayElement) {
          latpayElement.innerHTML = ""; // Clear the content
        }

        if (window.LatpayCheckout) {
          window.LatpayCheckout.open({
            merchantuserid: formData.Merchant_User_Id,
            publickey: formData.PublicKey,
            currency: formData.Currency,
            amount: formData.Amount,
            reference: formData.Reference,
            description: formData.Description,
            status: (status) => {
              console.log("status", status);
            },
          });
          window.LatpayCheckout.OnPaymentCompleted = (val) => {
            console.log("Payment completed:", val);
            setResult(val);
            if(val.responsekey!=""){
            const authRequest = {
              merchantid: formData.Merchant_User_Id,
              amount: formData.Amount,
              currency: formData.Currency,
              reference: formData.Reference,
              transactionkey: formData.AuthStatusCheck,
            };
            console.log(authRequest);

            fetch(
              "https://lateralpayments.com/checkout-staging/authorise/authstatuscheck",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(authRequest),
              }
            )
              .then((response) => response.json())
              .then((data) => {
                console.log(data);
                setAuthStatusCheck(data);
              })
              .catch((err) => {
                console.error("Error in Auth Status Check:", err);
              });
          };
        }
        } else {
          console.error("LatpayCheckout is not available");
        }
        // only for card
        // latpayCheckout.open_card({
        //   merchantuserid: formData.Merchant_User_Id,
        //   publickey: formData.PublicKey,
        //   currency: formData.Currency,
        //   amount: formData.Amount,
        //   reference: formData.Reference,
        //   description: formData.Description,
        //   status: (status) => {
        //     console.log("status", status);
        //   },
        // });

        // only for gpay
        // latpayCheckout.open_googlepay({
        //   merchantuserid: formData.Merchant_User_Id,
        //   publickey: formData.PublicKey,
        //   currency: formData.Currency,
        //   amount: formData.Amount,
        //   reference: formData.Reference,
        //   description: formData.Description,
        //   status: (status) => {
        //     console.log("status", status);
        //   },
        // });

        // only for applepay
        // latpayCheckout.open_applepay({
        //   merchantuserid: formData.Merchant_User_Id,
        //   publickey: formData.PublicKey,
        //   currency: formData.Currency,
        //   amount: formData.Amount,
        //   reference: formData.Reference,
        //   description: formData.Description,
        //   status: (status) => {
        //     console.log("status", status);
        //   },
        // });
      })
      .catch((error) => {
        console.error("Error loading scripts:", error);
      });

    return () => {
      const scripts = document.querySelectorAll("script[src]");
      scripts.forEach((script) => {
        if (
          script.src.includes("jquery") ||
          script.src.includes("latpay3.js")
        ) {
          document.body.removeChild(script);
        }
      });
    };
  }, [formData]); // Ensure formData is included in the dependency array if it changes

  const handleCheckout = (e) => {
    e.preventDefault(); // Prevent default button action if necessary

    if (window.LatpayCheckout) {
      window.LatpayCheckout.secure3DPayment({
        amount: formData.Amount,
        currency: formData.Currency,
        reference: formData.Reference,
        description: formData.Description,
        firstname: formData.Firstname,
        lastname: formData.Lastname,
        email: formData.Email,
        datakey: formData.DataKey,
        transkey: formData.TransKey,
        is3dcheck: "N",
      });
    } else {
      console.error("LatpayCheckout is not available");
    }
  };

  return (
    <div>
      <Container>
        {/* Your component content here */}
        <div id="latpay-element"></div>
        <Button onClick={handleCheckout}>Click to Checkout</Button>
        {result != "" && authStatusCheck != "" ? (
        <div
          style={{
            wordWrap: "break-word",
            wordBreak: "break-word",
            width: "60%",
            marginTop: "12px",
            marginBottom: "12px",
          }}
        >
          <div>
            <p>Result :</p>
            <p>{JSON.stringify(result)}</p>
          </div>
          <div>
            <p>Auth Status Check Result :</p>
            <p>{JSON.stringify(authStatusCheck)}</p>
          </div>
        </div>
      ) : (
        ""
      )}
      </Container>
     
    </div>
  );
};

export default Client;
